const PouplerCard = ({ card }) => {
    return (
        <div style={{ width: "calc(91% / 9)" }}>
            <img src={require(`./images/${card.src}.png`)} className="mb-2 w-100" alt={card.name} style={{ borderRadius: "5px", minHeight: "250px", maxHeight: "250px" }} />
            <div className="d-flex-between p-2 pt-1 pb-1" style={{ backgroundColor: "#0a1e30", borderRadius: "20px" }}>
                <p className="fw-bold text-white" style={{ fontSize: "12px" }}>{card.name}</p>
                <div className="d-flex-center">
                    <i className="fa-solid fa-eye me-2" style={{ color: "#00ceff" }}></i>
                    <p className="text-white" style={{ fontSize: "13px" }}>{card.rate}</p>
                </div>
            </div>
        </div>
    )
}
export default PouplerCard;