import { gameCardsData } from "../../../data";
import PouplerCard from "./PouplerCard";

const PouplerGames = () => {
    const renderCards = gameCardsData.map((card, index) => <PouplerCard key={index} card={card} />)
    return (
        <div className="text-white p-2 pt-4 pb-4 ps-2 ps-sm-3 ps-md-4 ps-lg-5 " style={{ backgroundColor: "#102d44d1" }}>
            <p className="fw-bold" style={{ color: "var(--text-color)" }}>POPULAR GAMES</p>
            <div className="d-flex-between mt-2 poupler-games">
                {renderCards}
            </div>
        </div>
    )
}

export default PouplerGames;