const Header = () => {
    return (
        <header className="d-flex justify-content-between p-3 pe-0 overflow-hidden" style={{ borderBottom: "1px solid var(--border-color)", position: "relative", zIndex: "3" }}>
            <div className="position-relative  search w-50 d-flex justify-content-end">
                <input type="text" placeholder="search for anything" />
                <i className="fa-solid fa-magnifying-glass position-absolute"></i>
            </div>
            <ul className="d-flex-center">
                <li className="me-2 me-sm-3 me-md-4 me-lg-5 text-white-50">
                    <i className="fa-solid fa-envelope"></i>
                </li>
                <li className="me-2 me-sm-3 me-md-4 me-lg-5  text-white-50">
                    <i className="fa-solid fa-bell"></i>
                </li>
                <li style={{ width: "80px" }}>

                </li>
            </ul>
        </header>
    )
}

export default Header;