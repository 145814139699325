export const gameCardsData = [
    { src: "one", name: "Fortn..", rate: "279" },
    { src: "two", name: "Leagen.", rate: "775" },
    { src: "three", name: "Minecraft", rate: "356" },
    { src: "four", name: "Black..", rate: "401" },
    { src: "five", name: "OverW..", rate: "266" },
    { src: "six", name: "PUBG..", rate: "399" },
    { src: "seven", name: "Throne..", rate: "305" },
    { src: "eight", name: "Black..", rate: "168" },
    { src: "nine", name: "Throne..", rate: "305" },
];
export const streamCardImages = ["one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "eleven", "twelev"];

export const streamCardsData = [
    {
        topText: "Trying out Battlefield clone",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Fortnite random stream",
        currntyText: "Trying out Battlefield clone",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Just Overwatch Fun",
        currntyText: "Trying it Out #BlackOps",
        upcomingText: "Fortnite random stream",
        clipsText: "Trying out Battlefield clone",
    },
    {
        topText: "Trying out Battlefield clone",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Fortnite random stream",
        currntyText: "Trying out Battlefield clone",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Just Overwatch Fun",
        currntyText: "Trying it Out #BlackOps",
        upcomingText: "Fortnite random stream",
        clipsText: "Trying out Battlefield clone",
    },
    {
        topText: "Trying out Battlefield clone",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Fortnite random stream",
        currntyText: "Trying out Battlefield clone",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Just Overwatch Fun",
        currntyText: "Trying it Out #BlackOps",
        upcomingText: "Fortnite random stream",
        clipsText: "Trying out Battlefield clone",
    },
    {
        topText: "Trying out Battlefield clone",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Fortnite random stream",
        currntyText: "Trying out Battlefield clone",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Just Overwatch Fun",
        currntyText: "Trying it Out #BlackOps",
        upcomingText: "Fortnite random stream",
        clipsText: "Trying out Battlefield clone",
    },
    {
        topText: "Trying out Battlefield clone",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Fortnite random stream",
        currntyText: "Trying out Battlefield clone",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Just Overwatch Fun",
        currntyText: "Trying it Out #BlackOps",
        upcomingText: "Fortnite random stream",
        clipsText: "Trying out Battlefield clone",
    },
    {
        topText: "Trying out Battlefield clone",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Fortnite random stream",
        currntyText: "Trying out Battlefield clone",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Just Overwatch Fun",
        currntyText: "Trying it Out #BlackOps",
        upcomingText: "Fortnite random stream",
        clipsText: "Trying out Battlefield clone",
    },
    {
        topText: "Trying out Battlefield clone",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Fortnite random stream",
        currntyText: "Trying out Battlefield clone",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Just Overwatch Fun",
        currntyText: "Trying it Out #BlackOps",
        upcomingText: "Fortnite random stream",
        clipsText: "Trying out Battlefield clone",
    },
    {
        topText: "Trying out Battlefield clone",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Fortnite random stream",
        currntyText: "Trying out Battlefield clone",
        upcomingText: "Trying it Out #BlackOps",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Trying it Out #BlackOps",
        currntyText: "Fortnite random stream",
        upcomingText: "Trying out Battlefield clone",
        clipsText: "Just Overwatch Fun",
    },
    {
        topText: "Just Overwatch Fun",
        currntyText: "Trying it Out #BlackOps",
        upcomingText: "Fortnite random stream",
        clipsText: "Trying out Battlefield clone",
    },
];

export const activeStreamCard = (e, currentSection, setCurrentSection) => {
    const bar = document.querySelectorAll(".filter-bar h6");
    // remove active calsses from all elements
    bar.forEach(ele => ele.classList.remove("active"));
    // add active class to current element
    e.target.classList.add("active");
    setCurrentSection(currentSection);
}

export const barText = [
    {
        text: "TOP STREAMS",
        cardName: "topText",
        active: "active",
    },
    {
        text: "CURRENTY ONLINE",
        cardName: "currntyText",
    },
    {
        text: "UPCOMING",
        cardName: "upcomingText",
    },
    {
        text: "CLIPS(FINISHED)",
        cardName: "clipsText",
    },
];