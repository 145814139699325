import NavItem from "./NavItem";

const Navbar = () => {
    return (
        <div className="nav-wrapper" style={{ borderRight: "1px solid var(--border-color)", zIndex: "3" }}>
            <i className="fa-solid text-white fa-bars d-flex-center mb-1"></i>
            <ul className="link-items d-flex align-items-start flex-column">
                <NavItem src={"one"} />
                <NavItem src={"two"} />
                <NavItem src={"three"} />
                <NavItem src={"four"} />
                <NavItem src={"five"} />
                <NavItem src={"six"} />
                <NavItem src={"seven"} />
            </ul>
        </div>
    )
}

export default Navbar;