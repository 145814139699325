const StreamCard = ({ card, currentSection, images }) => {
    return (
        <div className="p-2 mb-3" style={{ borderRadius: "10px", backgroundColor: "#011c289e", width: "calc(95% / 5)" }}>
            <img src={require(`./images/${images[Math.floor(Math.random() * images.length)]}.png`)} className="mb-3" width={"100%"} style={{ height: "150px", borderRadius: "10px" }} alt={card.topText} />
            <div className="d-flex-center mb-2">
                <img src={require(`./images/${images[Math.floor(Math.random() * images.length)]}.png`)} className="me-2" style={{ height: "35px", width: "35px", borderRadius: "50%" }} alt={card.topText} />
                <div className="fw-bold">
                    <p className="text-white">{card[currentSection]}</p>
                    <p className="text-white-50" style={{ fontSize: "11px" }}>{card[currentSection]}</p>
                </div>
            </div>
        </div>
    )
}
export default StreamCard;