import SidebarItem from "./Sidebar-item";
import SidebarHeader from "./SidebarHeader";

const UsersSidebar = () => {

    const endUsers = ["seven", "eight", "nine", "ten"];

    const renderEndusers = endUsers.map((user, index) => {
        return (
            <div key={index} className="p-2 d-flex-center" >
                <img src={require(`./images/${user}.png`)} alt={user} />
            </div>
        )
    })
    return (
        <div className="users-sidebar text-white-50" style={{ zIndex: "3" }}>
            <div>
                <div className="text-white-50 d-flex-center  position-relative" style={{ width: "80px", height: "65px", borderBottom: "1px solid var(--border-color)", borderLeft: "1px solid var(--border-color)" }}>
                    <i className="fa-solid fa-users"></i>
                </div>
                <SidebarHeader userCount={6} />
                <SidebarItem />
                <div className="mt-3">
                    <SidebarHeader userCount={6} />
                </div>
                {renderEndusers}
            </div>
        </div>
    )
}

export default UsersSidebar;