import { NavLink } from "react-router-dom";

const NavItem = ({ src }) => {
    return (
        <li className="link-item w-100">
            <NavLink to={"/"} className="link nav-link d-flex-center fw-bold">
                <img src={require(`./images/${src}.png`)} alt={src} />
            </NavLink>
        </li>
    )
}
export default NavItem;