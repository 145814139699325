const SidebarHeader = ({ userCount }) => {
    return (
        <div className="mb-3 d-flex justify-content-evenly align-items-center p-3" style={{ fontSize: "11px", backgroundColor: "#0a1f2c", borderBottom: "1px solid var(--border-color)" }}>
            <i className="fa-solid fa-users"></i>
            <span className="d-flex-center circlr-element me-1 ms-1" style={{ backgroundColor: "#435767", width: "20px", height: "20px" }}>{userCount}</span>
            <i className="fa-solid fa-angle-up"></i>
        </div>
    )
}

export default SidebarHeader;