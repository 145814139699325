const StreamWelcome = () => {
    return (
        <div className="text-white  p-2 pt-4 pb-4 ps-2 ps-sm-3 ps-md-4 ps-lg-5 " style={{ backgroundColor: "#102d44d1" }}>
            <div className="d-flex fw-bold align-items-center mb-3">
                <p className="me-2">Home</p>
                <i className="fa-solid fa-chevron-right me-2" style={{ fontSize: "11px" }}></i>
                <p style={{ color: "var(--text-color)" }}>Streems</p>
            </div>
            <div className="d-flex align-items-center stearm-btn">
                <h1 className="me-5">Streems</h1>
                <button className="min-btn d-flex align-items-center">
                    <i className="fa-solid fa-play d-flex-center circlr-element"></i>
                    <p className="ms-3">Start Stream</p>
                </button>
            </div>
        </div>
    )
}

export default StreamWelcome