const SidebarItem = () => {
    const usersImages = ["one", "two", "three", "four", "five", "six"];
    const renderImage = usersImages.map((user, index) => {
        return (
            <div key={index} className="p-2 d-flex-center" >
                <img src={require(`./images/${user}.png`)} alt={user} />
            </div>
        )
    })
    return renderImage
}

export default SidebarItem;