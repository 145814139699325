import { useEffect } from "react";
import PouplerGames from "./PouplerGames/PouplerGames";
import StreemCards from "./StreemCards/StreemCards";
import StreamWelcome from "./StreamWelcome";

const Home = () => {
    const toggleFullScreenImage = () => {
        const images = document.querySelectorAll(".home img");
        images.forEach(img => {
            img.style.cssText += "cursor: pointer !important;";
            img.addEventListener("click", () => {
                img.requestFullscreen();
            });
        });
    }
    useEffect(() => {
        toggleFullScreenImage();
    })
    return (
        <div style={{ width: "calc(100% - 80px)", position: "relative", zIndex: "3" }} className="home">
            <div className="cover-cards"></div>
            <StreamWelcome />
            <PouplerGames />
            <StreemCards />
        </div>
    )
}
export default Home;