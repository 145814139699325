import { useState } from "react";
import StreamCard from "./StreamCard";
import { activeStreamCard, barText, streamCardImages, streamCardsData } from "../../../data";

const StreemCards = () => {
    const [currentSection, setCurrentSection] = useState("topText");

    const renderBarText = barText.map((object, index) => <h6 key={index} style={{ fontSize: "13px", cursor: "pointer" }} onClick={(e) => activeStreamCard(e, object.cardName, setCurrentSection)} className={`me-2 me-md-3 me-lg-4 mb-0 ${object.active && object.active}`}>{object.text}</h6>)
    const renderCards = streamCardsData.map((card, index) => <StreamCard key={index} images={streamCardImages} currentSection={currentSection} card={card} />);

    return (
        <div className="p-2 pt-4 pb-4 ps-2 ps-sm-3 ps-md-4 ps-lg-5 " style={{ backgroundColor: "#102d44d1" }}>
            <div className="d-flex-between pb-2 mb-3 nav-filter-bar" style={{ borderBottom: "2px solid var(--border-color)" }}>
                <div className="d-flex text-white-50 filter-bar">
                    {renderBarText}
                </div>
                <div className="d-flex fw-bold">
                    <div className="d-flex-center me-4" style={{ color: "#009cff" }}>
                        <p className="me-2" style={{ fontSize: "12px" }}>FILTER</p>
                        <div className="circlr-element d-flex-center" style={{ width: "10px", height: "10px", backgroundColor: "#009cff" }}>
                            <div className="circlr-element" style={{ width: "6px", height: "6px", backgroundColor: "#102837" }}>

                            </div>
                        </div>
                    </div>
                    <div className="d-flex-center me-2" style={{ color: "#fa6438" }}>
                        <p className="me-2" style={{ fontSize: "12px" }}>SELECT</p>
                        <i className="fa-solid fa-play" style={{ fontSize: "10px", color: "#fa6438" }}></i>
                    </div>
                </div>
            </div>
            <div className="mb-2 mt-2 d-flex-between flex-wrap stream-cards">
                {renderCards}
            </div>
        </div>
    )
}

export default StreemCards;