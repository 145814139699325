import CoverBg from "./Components/CoverBg/CoverBg";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import UsersSidebar from "./Components/UsersSidebar/UsersSidebar";
function App() {
  return (
    <div className="App">
      <CoverBg />
      <Navbar />
      <div style={{ marginLeft: "60px" }}>
        <Header />
        <UsersSidebar />
        <Home />
      </div>
    </div>
  );
}

export default App;
